import React, { useState, useEffect } from "react";
import moment from "moment";
import requests from "../../../services/requests";
import ClipLoader from "react-spinners/ClipLoader";

const DetalhePosicao = ({ entity, positions, setPositions }) => {
    const [load, setLoad] = useState(false);
    const plates = entity.platesNotTrackable.split(",");

    const loadPositions = () => {
        setLoad(true);

        requests.getFreightPositions(entity.identifier).then(data => {
            setLoad(false);
            setPositions(data);
        });
    };

    useEffect(() => {
        loadPositions();
    });

    if (!positions || (positions.length === 0 && !load)) {
        if (entity.processes.length > 0) {
            return <p>CTE não rastreavel - Container</p>;
        } else if (plates.length === 1) {
            return <p>CTE não rastreavel - Truck</p>;
        } else if (entity?.isComplementary) {
            return <p>CTE não rastreavel - Complementar</p>;
        } else if (entity?.marketValue < 300000 || entity?.marketValue === undefined) {
            return <p>CTE não rastreavel - carga com valor abaixo de R$ 300.000,00</p>;
        } else {
            return <p>Sem posições</p>;
        }
    } else {
        return (
            <>
                {load && (
                    <div className="flex flex-col items-center">
                        <ClipLoader size={20} loading={load} />
                    </div>
                )}
                {positions && positions.length > 0 && (
                    <table className="table-auto mt-10 w-full text-left">
                        <thead className="border-b border-roxo_oficial">
                            <tr>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th>Distância</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {positions.map(position => (
                                <tr>
                                    <td>{position.latitude}</td>
                                    <td>{position.longitude}</td>
                                    <td>{position.distance}</td>
                                    <td>{moment(position.receivedAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </>
        );
    }
};

export default DetalhePosicao;
